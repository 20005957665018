import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../Atoms/Inputs/Text'
import { CreateVariableFields } from './CreateVariableFields'
import { CreateEntitiesFields } from './CreateEntitiesFields'
import { useParams } from 'react-router-dom'
import { getParametersByDataModelId } from '../../../store/parameter/api'

const entityType = 'parameter'
const entityURL = 'parameters'
const GROUPS = [
    { name: 'Client Characteristics', value: 1 },
    { name: 'Creditworthiness', value: 2 },
    { name: 'Product Class Characteristics', value: 3 },
    { name: 'Solvency', value: 4 },
    { name: 'Expense analysis', value: 5 },
    { name: 'Income analysis', value: 6 },
    { name: 'Limit level', value: 7 },
    { name: 'Limits', value: 8 },
    { name: 'Decision making', value: 9 },
    { name: 'Term of sales', value: 10 },
    { name: 'Global', value: 11 },
]

const TYPES = [
    { name: 'Variable', value: 1 },
    { name: 'Script', value: 2 },
    { name: 'Value Setter', value: 3 },
    { name: 'Score Card', value: 4 },
]
export const CreateParameter = () => {
    const params = useParams()
    const [globalParameters, setGlobalParameters] = useState([])
    const [entity, setEntity] = useState({
        name: '',
        description: '',
        key: '',
        group: 1,
        selectedType: null,
        validationParameters: [],
        type: 1,
        defaultValue: '',
    })
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }
    useEffect(() => {
        getParametersByDataModelId({ modelId: params.modelId }, []).then(
            (_parameters) => {
                const list1 = _parameters
                    .filter((item) => item.group === 11)
                    .map((item) => {
                        return { label: item.name, value: item._id }
                    })
                setGlobalParameters(list1)
            },
        )
    }, [])
    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="single-table-wrapper parameter-content">
                        <div className="inputsWrapper">
                            <div className="row">
                                <TextInput
                                    label="Name"
                                    value={entity.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                            </div>
                            <div className="row">
                                <SelectWithLabelInput
                                    label="Group"
                                    defaultValue={entity.group}
                                    onChange={({ target }) => {
                                        onEntityChange('group', target.value)
                                    }}
                                    options={GROUPS}
                                />
                            </div>
                            <div className="row">
                                <SelectWithLabelInput
                                    label="Type"
                                    defaultValue={entity.type}
                                    onChange={({ target }) => {
                                        console.log(target.value)
                                        onEntityChange('type', target.value)
                                    }}
                                    options={TYPES}
                                />
                            </div>
                            {entity.type == 1 ? (
                                <CreateVariableFields
                                    entity={entity}
                                    onEntityChange={onEntityChange}
                                />
                            ) : (
                                <CreateEntitiesFields
                                    entity={entity}
                                    globalParameters={globalParameters}
                                    onEntityChange={onEntityChange}
                                />
                            )}
                            <div className="row">
                                <label>
                                    Description
                                    <textarea
                                        value={entity.description}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'description',
                                                target.value,
                                            )
                                        }}
                                        placeholder="Description"
                                        style={{
                                            width: '100%',
                                            borderRadius: '7px',
                                            marginTop: '7px',
                                            height: 'calc(100vh - 600px)',
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
