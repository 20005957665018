import React, { useEffect, useState } from 'react'

import { Node } from './value-setter-node'
import { RenderCondition } from './value-setter-condition'
import { getSegmentById } from '../../../store/segment/api'
import { handleError } from '../../../utils/handleErrors'

export const Segment = ({
    stateCurrentTree,
    stateLevelLength,
    stateCurrentVariables,
    removeVariable,
    addSegmentChild,
    toggleRow,
    setSegmentArchivePopup,
    updateVariable,
}) => {
    const tree = stateCurrentTree
    // const [tree, setTree] = useState({})

    // useEffect(() => {
    //     if (stateCurrentTree) {
    //         getSegmentById({ id: stateCurrentTree.segmentId })
    //             .then(({ segment }) => {
    //                 const valueSetterConditions = stateCurrentTree.conditions
    //                 const conditions = segment.conditions.map((c) => {
    //                     const f = valueSetterConditions.find(
    //                         (s) => s.id === c.id,
    //                     )
    //                     const vars = {}
    //                     stateCurrentVariables.forEach((v) => {
    //                         vars[v.name] = f[v.name] ? f[v.name] : ''
    //                     })
    //                     console.log('f', f, c, vars)
    //                     return {
    //                         ...c,
    //                         ...vars,
    //                         children: f.children ?? [],
    //                         isOpen: f.isOpen ?? false,
    //                         level: f.level ?? 1,
    //                         main: false,
    //                     }
    //                 })
    //                 setTree({
    //                     ...stateCurrentTree,
    //                     conditions,
    //                 })
    //
    //                 console.log('tree', {
    //                     ...stateCurrentTree,
    //                     conditions,
    //                 })
    //                 console.log('stateCurrentTree', stateCurrentTree)
    //             })
    //             .catch(handleError)
    //     }
    // }, [stateCurrentTree])
    return (
        <div className="formTableWrapper">
            {stateCurrentTree && stateCurrentTree.main && (
                <Node
                    stateCurrentTree={stateCurrentTree}
                    level={stateCurrentTree.level}
                    stateLevelLength={stateLevelLength}
                    stateCurrentVariables={stateCurrentVariables}
                    removeVariable={removeVariable}
                />
            )}

            <div className="formTable">
                {stateCurrentTree &&
                    stateCurrentTree?.conditions?.map((item) => {
                        return (
                            <RenderCondition
                                level={stateCurrentTree.level}
                                stateLevelLength={stateLevelLength}
                                updateVariable={updateVariable}
                                key={item.id}
                                removeVariable={removeVariable}
                                treeId={tree.treeId}
                                item={item}
                                toggleRow={toggleRow}
                                stateCurrentVariables={stateCurrentVariables}
                                addSegmentChild={addSegmentChild}
                                setSegmentArchivePopup={setSegmentArchivePopup}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
